import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device } from '../styles/device';




export const ButtonCtaStyled = styled.div`
    display: block;

    a {
        color: ${palette.white};
        font-size: ${palette.fontSizeMd};
        font-weight: 700;
        padding: 1rem 1.6rem;
        margin-top: 0;
        background-color: ${palette.magenta};
        text-decoration: none;
        text-transform: uppercase;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 300px;
        margin: 0 auto;

        @media ${device.tablet} {
            padding: 1rem 2rem;
        }

        svg {
            margin-left: 1rem;
            font-size: 0.9rem;
            transition: transform 0.2s;
        }

        &:hover {
            background-color: ${palette.darkMagenta};
            box-shadow: 0 10px 25px -5px rgba(243, 11, 135,.5);
        }

        &:hover svg {
            transform: translateX(70%);
        }
    }

`;