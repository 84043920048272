import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types'
import ScrollHook from './helper/scrollHook'
import {TitleStyled} from './titleStyled'


const Title = ({position, className}) => {
    const [scrollToDiv, setScrollToDiv] = useState(false)
    const isScroll = ScrollHook()

    useEffect(() => {
        const title = document.getElementById('titleMain');
        const limit = title.offsetTop - 800
        if(isScroll > limit) {
            setScrollToDiv(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isScroll])

    const data = useStaticQuery(graphql`
    query TitleQuery {
        allContentfulTitle {
            edges {
                node {
                  id
                  text
                  title
                  position
                }
            }
        }
    }
`)
    const lineAnimateClass = scrollToDiv ? `underLine active` : `underLine`
    if(!data.allContentfulTitle.edges) {
        return <div>Loading...</div>
    }
    return (
        data.allContentfulTitle.edges.map((edge) => {
                if(edge.node.position === position) {
                    return <TitleStyled key={edge.node.id} className={className} id="titleMain">
                                <h1 className="title">{edge.node.title}
                                    <div className={lineAnimateClass}/>
                                </h1>
                                <p className="text">{edge.node.text}</p>
                            </TitleStyled>
                }
                return true
            }
        )
    )
}

Title.propTypes = {
    position: PropTypes.oneOf(['Home', 'Platform', 'Poker', 'Casino', 'News', 'Contact']).isRequired, // Nav
    className: PropTypes.string
  }

export default Title



