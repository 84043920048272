import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Particle from './particle'
import {BannerWrapStyled} from './heroStyled'
import PropTypes from 'prop-types'
import ContentHero from './contentHero'




const Hero = ({position, children, noBackgroundHero,
  bannerImage, buttonText, classButton, iconButton, className, twoRows}) => {
  const data = useStaticQuery(graphql`
  query HeroQuery {
    allContentfulHero {
      edges {
        node {
          image {
            file {
              url
            }
            sizes(quality: 90, maxHeight: 1000, resizingBehavior: PAD) {
              ...GatsbyContentfulSizes_withWebp
              sizes
              src
              srcSet
              base64
            }
          }
          name
          id
          text1
          text2
          text3
          bannerPosition
          displayParticles
          bannerImage {
            title
            file {
              url
            }
            sizes(maxHeight: 1000, quality: 90, resizingBehavior: PAD) {
              ...GatsbyContentfulSizes_withWebp
              sizes
              src
              srcSet
              base64
            }
          }
        }
      }
    }
  }
  `)

  if(!data.allContentfulHero.edges) {
    return <div>Loading...</div>
  } else {
    return data.allContentfulHero.edges.map((edge) => {
      if(edge.node && edge.node.bannerPosition &&
        edge.node.name && (position === edge.node.bannerPosition[0])) {
        return <BannerWrapStyled key={edge.node.id} className={className}>
                  {noBackgroundHero ? null :
                  // <Img alt={edge.node.name} sizes={edge.node.image.sizes}/>
                  <img src={edge.node.image && edge.node.image.file.url} alt={edge.node.name} />
                  }
                  <Particle show={edge.node.displayParticles}/>
                  <ContentHero
                    bannerImage={bannerImage}
                    node={edge.node}
                    buttonText={buttonText}
                    classButton={classButton}
                    iconButton={iconButton}
                    className={className}
                    twoRows={twoRows}
                  />
                    {children}
                </BannerWrapStyled>
      }
      return true;
    })
  }
}

Hero.propTypes = {
  position: PropTypes.oneOf(['Home', 'Platform', 'Poker', 'Casino', 'News', 'Contact']).isRequired, // Nav
  className: PropTypes.string.isRequired,
  noBackgroundHero: PropTypes.bool.isRequired
}

export default Hero
