import React from 'react'
import Particles from 'react-particles-js';
import PropTypes from 'prop-types'


const particleOpt = {
    particles: {
        number: {
            value: 1000,
            density: {
                enable: true,
                value_area: 8000
            }
        }
    }
}

const Particle = ({show}) => {
    const display = show ? 'block' : 'none'
  return <Particles
                className='particles'
                canvasClassName="canvasParticles"
                params={particleOpt}
                style={{
                    width: '100%',
                    backgroundImage: `linear-gradient(357deg,#2a9dc4,#662dc3)`,
                    //background: 'transparent',
                    position: 'absolute',
                    top: 0,
                    opacity: 0.5,
                    display: display
            }}
        />
}

Particle.propTypes = {
    show: PropTypes.bool.isRequired
}

export default Particle
