import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device } from '../styles/device'



export const TitleStyled = styled.div`
    text-align: left;

    &.twoColumns {
        @media ${device.tablet} {
            margin-top: 30px;
        }
    }

    .title {
        font-family: 'bebas-neue', sans-serif;
        font-size: ${palette.fontSizeXl};
        font-weight: ${palette.fontWeightTitle};
        display: inline-block;

        @media ${device.tablet} {
            font-size: ${palette.fontSizeTitle};
        }
    }

    .text {
        font-size: ${palette.fontSizeMd};
        font-weight: ${palette.fontWeightText};
        line-height: ${palette.lineHeightSm};
    }

    .underLine {
        width: 25%;
        border-bottom: 5px solid ${palette.magenta};
        padding-top: 1rem;
        transition: .7s ease-in-out;

        &.active {
            width: 100%;
        }
    }

    -webkit-animation: opacityAni 5s; /* Safari 4+ */
    -moz-animation:    opacityAni 5s; /* Fx 5+ */
    -o-animation:      opacityAni 5s; /* Opera 12+ */
    animation:         opacityAni 5s; /* IE 10+, Fx 29+ */

    @keyframes opacityAni {
        0%   { opacity: 0; }
        100% { opacity: 1; }
    }
`;

