import React from 'react'
import ButtonCta from './buttonCta'
import PropTypes from 'prop-types'
import {ContentStyled, TextWrapStyled} from './heroStyled'


const ContentHero = ({buttonText, classButton, iconButton, bannerImage, node, className, twoRows}) => {
  return <ContentStyled bannerImage={bannerImage} className={className}>
            <div className="container">
              <div className="wrapper">
                  <TextWrapStyled bannerImage={bannerImage}>
                      <p>{node.text1}</p>
                      <h1 className="bigText">{node.text2}</h1>
                      <p>{node.text3}</p>
                  </TextWrapStyled>
                  <ButtonCta text={buttonText} className={classButton} iconButton={iconButton}/>
              </div>
              {twoRows ? <div className="secondSection">
                <img src={node.bannerImage && node.bannerImage.file.url} alt={node.bannerImage.title} />
              </div> : null}
            </div>
        </ContentStyled>
}

ContentHero.propTypes = {
    bannerImage: PropTypes.bool.isRequired,
    twoRows: PropTypes.bool
  }

export default ContentHero
