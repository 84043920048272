import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device, width } from '../styles/device';


export const BannerWrapStyled = styled.div`
    position: relative;
    height: 600px;

    @media ${device.tablet} {
        height: 600px;
    }

    .gatsby-image-wrapper, img {
        height: 600px;
        object-fit: cover;

        @media ${device.tablet} {
            height: 600px;
        }
    }

    &.homeHero {
        background-image: linear-gradient(120deg,#2b10ec,hsl(283, 94%, 29%));
        height: 600px;
        object-fit: cover;

        .gatsby-image-wrapper {
            opacity: 0.2;
            height: 600px;
        }
        img {
            opacity: 0.2;
            height: 600px;
            object-fit: cover;
        }
    }

    &.pokerContent {
        .gatsby-image-wrapper {

            height: 100%;

            img {
                height: 100%;
            }
        }
        img {
            height: 100%;
            object-fit: cover;
        }
    }

    &.casinoHero {
        background-image: linear-gradient(120deg,#1f0934,hsl(283,94%,29%));
        height: 600px;

        .container {
            margin-top: 0;
        }

        .casinoCta {
            margin-top: 30px;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    &.platformHero {
        background-image: linear-gradient(120deg,#1f0934,hsl(260, 93%, 36%));
        height: 600px;

        .container {
            margin-top: 0;
            justify-content: space-between;
            flex-direction: column-reverse;

            @media ${device.laptop} {
                flex-direction: row;
            }

            .wrapper {
                text-align: center;
                height: 500px;

                @media ${device.laptop} {
                    text-align: left;
                    height: auto;
                }
            }

            .secondSection {
                flex-basis: 100%;
                padding-right: 0;
                text-align: right;

                @media ${device.laptop} {
                    flex-basis: 50%;
                    padding-right: 15px;
                }

                .gatsby-image-wrapper {
                    width: 200px;
                    margin: 0 0 0 auto;
                    height: 300px;

                    @media ${device.laptop} {
                        width: 70%;
                    }

                    img {
                        object-fit: contain !important;
                    }
                }
                img {
                    width: 200px;
                    margin: 0 0 0 auto;
                    height: 300px;
                    object-fit: contain !important;

                    @media ${device.laptop} {
                        width: 70%;
                    }
                }
            }

            .bigText {
                display: none;

                @media ${device.laptop} {
                    display: block;
                }

            }
        }

        .platformCta {
            margin-top: 30px;
            padding-right: 15px;
            padding-left: 15px;

            a {
                margin-left: auto;

                @media ${device.laptop} {
                    margin-left: 0;
                }
            }
        }

    }

    &.contactHero {
        .container {
            margin-top: 0;
        }

        .bigText {
            text-shadow: ${palette.textShadowDarkGrey};
        }

        .contactCta {
            display: none;
        }

    }
`;

export const ContentStyled = styled.div`
    position: absolute;
    top: 0;
    text-align: center;
    color: ${palette.white};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;

    @media ${device.tablet} {
        height: 600px;
    }

     .container {
        width: ${width.xl};
        /* margin: 0 auto;     Broke in IE*/
        height: calc(100% - 80px);
        margin-top: ${palette.headerHeight};
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.pokerContent {
        .container {
            margin-top: 0;
        }
    }

    &.homeHero {
        .container {
            margin-top: 0;
        }
    }
    .heroLanding, .pokerCta {
        margin-top: 30px;
        padding-right: 15px;
        padding-left: 15px;
    }


    -webkit-animation: float .5s normal ease-out;
    -moz-animation: float .5s normal ease-out;
    -o-animation: float .5s normal ease-out;
    animation: float .5s normal ease-out;

    @-webkit-keyframes float {
        0% {
            opacity: 0;
            top:50px;
        }
        100% {
            opacity: 1;
            top: 0px;
        }
    }
    @keyframes float {
        0% {
            opacity: 0;
            top:50px;
        }
        100% {
            opacity: 1;
            top: 0px;
        }
    }
`;

export const TextWrapStyled = styled.div`
    padding: 0 15px;
    max-width: ${width.lg};
    margin: 0 auto;

    p {
        margin-bottom: 20px;
        font-size: ${palette.fontSizeReg};
        text-shadow: ${palette.textShadowGrey};
    }

    h1 {
        font-family: 'bebas-neue', sans-serif;
        font-size: ${palette.fontSizeXl};
        font-weight: ${palette.fontWeightTitle};
        margin-bottom: 15px;
        text-shadow: ${palette.textShadowGrey};

            @media ${device.tablet} {
                font-size: ${palette.fontSizeXxl};
            }


        }

    p:nth-child(3) {
        line-height: ${palette.lineHeightLg};
        text-shadow: ${palette.textShadowGrey};
    }
`;
