import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import {ButtonCtaStyled} from './buttonCtaStyled'

const ButtonCta = ({ text, className, iconButton }) => {
  return (
    <ButtonCtaStyled className={className}>
      <Link to="/contact/" role='button'>
          {text}
        </Link>
    </ButtonCtaStyled>
    )
}

ButtonCta.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconButton: PropTypes.object
}

export default ButtonCta
